@tailwind base;
@tailwind components;
@tailwind utilities;

.align-middle {
    @apply justify-center items-center;
}
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.float-label {
    display: flex;
    flex-direction: column;
    position: relative;
    transform-origin: top left;
    transition: all 0.2s ease-out;
}

.float-label input {
    width: 100%;
    padding-top: 30px;
}

.float-label p {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    pointer-events: none;
    position: absolute;
    transform: translate(0, 34px) scale(1);
    font-size: larger;
    padding-left: 5px;
}

.float-label:focus-within p,
.float-label .active {
    transform: translate(-2px, 5px) scale(0.8);
}

.Toastify {
    font-size: 14px;
}

.Toastify .Toastify__toast-container {
    width: 400px;
}

.Toastify__toast-theme--dark {
    background: #161d27 !important;
}

.react-datepicker__tab-loop__start {
  z-index: 99999;
}

.react-datepicker__day-name,
.react-datepicker__current-month {
  @apply first-letter:uppercase;
}

.react-datepicker__navigation-icon--previous,
.react-datepicker__navigation-icon--next {
  @apply mt-2;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 38px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 40;
  @apply border-[1px] border-gray-900
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 4px 20px;
  @apply bg-white dark:bg-gray-800 text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-800
}

.react-autosuggest__suggestion .suggestion-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-autosuggest__suggestion:not(:first-child) {
  /* border-top: 1px solid #ddd; */
  @apply border-t-[1px] border-gray-900
}

.react-autosuggest__suggestion--highlighted {
  background-color: #467fcf;
  color: #fff;
}

.react-autosuggest__suggestion--highlighted .highlight {
  color: #120000;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.name {
  margin-left: 68px;
  line-height: 45px;
}

.highlight {
  color: #e00;
  font-weight: bold;
}
